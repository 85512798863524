<template>
	<base-layout>
		<template v-slot:toolbar>
			<ion-buttons slot="end">
				<ion-back-button color="light" @click="backToSignIn" default-href="/signin" />
			</ion-buttons>
		</template>
		<ion-grid>
			<ion-row>
				<ion-col>
					<h1 style="color: white">Sign Up</h1>
					<h5 style="color: white">Create your account and get started with Runxact!</h5>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-input
						placeholder="Email"
						type="email"
						class="inputBox"
						required="true"
						id="email"
					></ion-input>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-input
						placeholder="Password"
						type="password"
						class="inputBox"
						required="true"
						id="pass1"
						minlength="7"
					></ion-input>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-input
						placeholder="Confirm Password"
						type="password"
						class="inputBox"
						required="true"
						id="pass2"
						minlength="7"
					></ion-input>
				</ion-col>
			</ion-row>
			<ion-row>
				<ion-col>
					<ion-button class="inputBox signInButton" @click="onButtonClick"
						>Sign Up</ion-button
					>
					<h3 class="noMatchText" id="noMatch">Passwords don't match</h3>
				</ion-col></ion-row
			><ion-row>
				<ion-col>
					<ion-button class="inputBox signInButton" @click="googleSignIn">
						<span>Or Sign in with Google</span>
						<span>
							<ion-icon class="googIcon" :icon="logoGoogle" />
						</span>
					</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</base-layout>
</template>

<script>
	import {
		IonButtons,
		IonBackButton,
		IonInput,
		IonButton,
		IonItem,
		IonList,
		IonIcon,
	} from "@ionic/vue";

	import { createToast } from "@/services/toast.js";

	import { emailSignUp, googleAuth } from "@/services/firebase.js";

	import { logoGoogle } from "ionicons/icons";

	export default {
		setup() {
			return {
				logoGoogle,
			};
		},
		methods: {
			backToSignIn() {
				this.$router.push("/signin");
			},
			onButtonClick() {
				if (this.checkCredentials()) {
					console.debug(this.email, this.password1);
					emailSignUp(this.email, this.password1)
						.then((res) => {
							console.debug(res);
							this.$router.push("/signin/signup/details1");
						})
						.catch((e) => {
							createToast(e.message);
						});
				}
			},
			checkCredentials() {
				//console.debug(this)
				let pass1 = document.getElementById("pass1").value;
				let pass2 = document.getElementById("pass2").value;
				let email = document.getElementById("email").value;
				console.debug(document.getElementById("email"));
				console.debug(email, pass1, pass2);
				this.password1 = pass1;
				this.password2 = pass2;
				this.email = email;
				if (!this.checkEmail(email)) {
					createToast(`Your email is not in a supported format`);
				} else if (pass1 != pass2) {
					createToast(`Your passwords do not match`);
					return false;
				} else if (pass1.length < 7) {
					createToast(`Your password must be longer than 7 characters`);
				} else {
					console.debug("passwords do match");
					this.passwordsDoNotMatch = false;
					document.getElementById("noMatch").style.opacity = 0.0;
					return true;
				}
			},
			checkEmail(email) {
				return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
			},
			googleSignIn: async function () {
				googleAuth()
					.then((res) => {
						console.debug(res);
						console.debug("signin success");
						this.$router.push("/signin/signup/details1");
					})
					.catch((e) => console.debug(e));
			},
		},
		components: [IonButtons, IonBackButton, IonInput, IonButton, IonItem, IonList, IonIcon],
		data() {
			return {
				email: "",
				password1: "",
				password2: "",
				passwordsDoNotMatch: false,
			};
		},
		mounted() {
			document.getElementById("noMatch").style.display = "none";
			/*let firebaseScript = document.createElement('script')
        firebaseScript.setAttribute('src', 'https://www.gstatic.com/firebasejs/9.1.3/firebase-app.js')
        document.head.appendChild(firebaseScript)*/
			console.debug(document);
		},
	};
</script>

<style scoped>
	.inputBox {
		color: var(--ion-color-light-contrast);
		background: var(--ion-color-light-tint);
		/*border: 2px solid white;*/
		width: 80%;
		height: 40px;
		box-shadow: 2px;
		margin: 10px;
	}

	.signInButton {
		color: black;
		font-weight: bold;
	}

	.noMatchText {
		color: red;
		font-size: 14px;
		position: relative;
		width: 100%;
		text-align: center;
		/*border: 2px solid blue;*/
		opacity: 0;
	}
	.googIcon {
		transform: translateX(10px) translateY(1px);
	}
</style>
